<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ header }}</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <div class="mt-5">
          <v-select
            v-model="formData.assigned_to"
            label="Assign to"
            item-text="text"
            item-value="index"
            :items="users"
            clearable
            dense
            outlined
            :error-messages="assignto_errors"
            @input="$v.formData.assigned_to.$touch()"
            @blur="$v.formData.assigned_to.$touch()"
          ></v-select>
        </div>

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn--export-filter mr-3 px-5 py-3 ls1"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {
  UPDATE_RECOUNT_TASK,
  UPDATE_TABLE_DATA,
  UPDATE_ASSIGN_USER,
} from "@/core/services/store/standardCycleCount.module";

export default {
  name: "RecountCycleTaskModal",
  mixins: [validationMixin],
  props: ["pageLoading"],
  validations: {
    formData: {
      assigned_to: { required },
    },
  },
  data: () => ({
    dialog: false,
    formData: {
      assigned_to: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.formData = {
          assigned_to: null,
        };
        this.$v.$reset();
      }
      this.dialog = !this.dialog;
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.pageLoading(true);
      const payload = {
        warehouse_id: this.selectedWarehouse,
        id: this.item.id,
        ...this.formData,
      };
      if (this.actionType === "recount") {
        this.$store.dispatch(UPDATE_RECOUNT_TASK, payload).then(() => {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: `Cycle count updated`,
            timer: 2500,
          });
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            ...this.table_state,
            warehouse_id: this.selectedWarehouse,
          });
        });
      } else {
        this.$store.dispatch(UPDATE_ASSIGN_USER, payload).then(() => {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: `User Assigned for Cycle Task`,
            timer: 2500,
          });
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            ...this.table_state,
            warehouse_id: this.selectedWarehouse,
          });
        });
      }
      this.toggleModal();
    },
  },
  computed: {
    assignto_errors: function () {
      return this.handleFormValidation("assigned_to", this);
    },
    users: function () {
      return this.$store.getters.getSTANDARDCYCLECOUNTUsers;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    table_state: function () {
      return this.$store.getters.getRANDOMCYCLECOUNTTableState;
    },
    item: function () {
      return this.$store.getters.getItemForAction;
    },
    actionType: function () {
      return this.$store.getters.getSTANDARDCYCLECOUNTActionType;
    },
    header: function () {
      return this.actionType === "recount" ? "Recount Task" : "Assign user";
    },
  },
};
</script>

<style lang="scss">
.v-radio {
  .v-label {
    margin-bottom: 0px;
  }
}
</style>
